.link {
  composes: link from '../../../styles/common.module.scss';
  color: var(--secondary-color-5);
  cursor: pointer;

  .contentWrapper {
    position: relative;
    display: inline-block;
    white-space: nowrap;

    i {
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 0.5px;
      background-color: var(--secondary-color-5);
      transition: var(--base-transition);

      &.fadeInUnderline {
        opacity: 0;
      }
    }
  }

  &:hover,
  &:focus {
    color: var(--secondary-color-5);

    i {
      &.dropDownUnderline {
        bottom: -6px;
      }

      &.fadeInUnderline {
        opacity: 1;
      }
    }
  }
}

.invertedColors {
  color: var(--white);

  .contentWrapper {
    i {
      background-color: var(--white);
    }
  }

  &:hover {
    color: var(--white);
  }
}

.textEllipsis {
  &:hover,
  &:focus {
    i {
      bottom: 0;
    }
  }
}

.textEllipsisSpan {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
}
